
export function formatDate(date) {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Adiciona zero se o mês tiver apenas um dígito
    const day = String(d.getUTCDate()).padStart(2, '0'); // Adiciona zero se o dia tiver apenas um dígito
    return `${year}-${month}-${day}`;
}

export function formatDate2(date) {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Adiciona zero se o mês tiver apenas um dígito
    const day = String(d.getUTCDate()).padStart(2, '0'); // Adiciona zero se o dia tiver apenas um dígito
    return `${day}/${month}/${year}`;
}
