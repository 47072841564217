<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('week.title') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <!-- <div class="col-12 row p-2 m-0">
                    <label for="from" class="col-12">{{  t('week.range') }}</label>
                    <div class="col-12 col-md-3 col-lg-2 p-1">
                        <input id="from" type="date" name="from" v-model="range.from" class="form-control">
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 p-1">
                        <input id="to" type="date" name="to" v-model="range.to" class="form-control">
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 p-1">
                        <button class="btn btn-primary" @click="getWeeks">
                            {{ t('general.search') }}
                        </button>
                    </div>
                </div> -->
                <data-table v-if="weeks" class="table table-striped table-bordered" :data="weeks" :columns="columns"
                    :options="{responsive: true, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('treinos.clube') }}</th>
                            <th>{{ t('week.range') }}</th>
                            <th>{{ t('week.dep') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { deleteExercice } from '@/utilities/HelpFunctions.js'
    import api from '@/utilities/Api.js'
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc';
    
    dayjs.extend(utc);
    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
        },
        data() {
            return {
                weeks: null,
                showModal: false,
                printNow: false,
                onSelect: false,
                range: {
                    from: new Date(),
                    to: null,
                },
                columns: [
                    {data: 'id'},
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-eye' data-id='${data.id}' data-action='view'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-warning' data-id='${data.id}' data-action='edit'>
                                    <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: 'cabecalho'},
                    {data: null, render: data => {
                        const conf = JSON.parse(data.config)
                        if (conf.range && conf.range[1] && conf.range[1].length > 0) {
                            return conf.range[1]
                        }
                        return `semana ${dayjs.utc(data.dataInicio).format("DD.MM.YYYY")} - ${dayjs.utc(data.dataFim).format("DD.MM.YYYY")}`
                    }},
                    {data: 'subtitulo'},
                ],
                selected: null,
                view: null,
            }
        },
        created() {
            document.title = t('week.title')
            this.getWeeks()
        },
        
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'put', 'delete']),
            async getWeeks() {
                this.SET_LOADING(true)

                // if (!this.range.from || !this.range.to) {
                //     alert(t('week.select'))
                //     this.SET_LOADING(false)
                //     return
                // }

                // const params = new URLSearchParams()
                // params.append('from', formatDate(this.range.from))
                // params.append('to', formatDate(this.range.to))

                const response = await this.get({
                    url: `semana`
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                } else {
                    console.log(response.data)
                    this.weeks = response.data.semanas
                }
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    let [week] = this.weeks.filter(w => w.id == event.target.getAttribute("data-id"))

                    switch(event.target.getAttribute("data-action")) {
                        case 'edit':
                            // get image
                            this.$router.push('/week/' + week.id)
                            break
                        case 'delete':
                            // confirm delete
                            if (confirm(t('general.do-you-want'))) {
                                this.SET_LOADING(true)
                                await deleteExercice(week.id, this.delete, 'semana')
                                await this.getWeeks()
                                this.SET_LOADING(false)
                            }
                            break
                        case 'view':
                            this.print(week.id)
                            break
                    }
                }
            },
            t,
            print(id) {
                const p = new URLSearchParams()
                const lang = localStorage.getItem("lang_code") ? localStorage.getItem("lang_code").substring(0, 2).substring(0, 2):"pt"
                p.append("auth_token", sessionStorage.getItem("auth_token"))
                p.append("lang", lang)
                p.append("id", id)
                open(api.printUrl + "/week/" + id + "?" + p.toString(), "_blanck")
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
</style>
