<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 com-md-11 col-sm-9" v-if="treino">
            <div class="card card-primary">
                <!-- card-header -->
                <div class="card-header">
                    <h3 class="card-title">{{ t('treinos.details') }}</h3>

                    <!-- card-tools -->
                    <div class="card-tools">    
                        <button type="button" class="btn btn-tool" data-card-widget="maximize">
                            <i class="fas fa-expand"></i>
                        </button>
                    </div>
                    <!-- /.card-tools -->
                </div>
                <!-- /.card-header -->

                <form @submit.prevent="update">
                    <!-- card-body -->
                    <div class="card-body">
                        <div class="col-12 row m-0 p-1 justify-content-around">
                            <div class="col-12">
                                <input class="form-control" required :placeholder="t('treino.coach')" v-model="treino.title">
                            </div>
                            <div class="col-12 col-sm-5 position-relative p-2">
                                <input type="file" ref="file" class="d-none" @change="changeFile">
                                <img class="image-responsive responsive col-12" :src="treino.img_url">
                                <button type="button" class="btn btn-secondary position-absolute text-white upload-btn" id="upload-btn" @click="() => {
                                    this.$refs.file.click()
                                }">
                                    <i class="fa fa-upload"></i>
                                </button>
                                
                            </div>
                            <div class="col-12 col-md-7">
                                <div class="col-12 p-0 m-0">
                                    <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                        <input :placeholder="t('treinos.prof')" id="h_teacher" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_teacher">
                                        <label for="h_teacher" role="button" class="fa fa-edit"></label>
                                    </div>
                                    <input required type="text" :placeholder="t('treinos.prof')" class="form-control" v-model="treino.teacher" />
                                </div>

                                <div class="col-12 p-0 m-0">
                                    <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                        <input :placeholder="t('gifs.student')" id="h_student" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_student">
                                        <label for="h_student" role="button" class="fa fa-edit"></label>
                                    </div>
                                    <input required type="text" :placeholder="t('gifs.student')" class="form-control" v-model="treino.student" />
                                </div>

                                <div class="col-12 p-0 m-0">
                                    <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                        <input :placeholder="t('treinos.date')" id="h_data" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_data">
                                        <label for="h_data" role="button" class="fa fa-edit"></label>
                                    </div>
                                    <input required type="date" :placeholder="t('treinos.date')" class="form-control" v-model="treino.data" />
                                </div>

                                <div class="col-12 p-0 m-0">
                                    <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                        <input :placeholder="t('gifs.volume')" id="h_volume" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_volume">
                                        <label for="h_volume" role="button" class="fa fa-edit"></label>
                                    </div>
                                    <input required type="text" :placeholder="t('gifs.volume')" class="form-control" v-model="treino.volume" />
                                </div>
                            </div>
                            <form-group-component class="col-12 pt-0">
                                <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                    <input :placeholder="t('treinos.description')" id="h_objectives" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_objectives">
                                    <label for="h_objectives" role="button" class="fa fa-edit"></label>
                                </div>
                                <textarea required rows="3" class="form-control" v-model="treino.objectives"></textarea>
                            </form-group-component>
                            <form-group-component class="col-12 pt-0">
                                <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                                    <input :placeholder="t('post-work.title')" id="h_rate" class="edit col-10 border-none text-bold" v-model="treino.h_rate">
                                    <label for="h_rate" role="button" class="fa fa-edit"></label>
                                </div>
                                <ckeditor
                                    v-model="treino.rate"
                                    :editor="editor"
                                    :config="editorConfig"
                                />
                            </form-group-component>
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button class="btn btn-primary">
                            {{ t('general.update_data') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('exercices.of') }}</h3>
            </div>
            <div class="card-body table-responsive" id="my-table">
                <div class='icheck-primary'>
                    <input type="checkbox" v-model="postwork" name='printpostwork' id="printpostwork">
                    <label for='printpostwork'>{{ t('datatable.print') }} {{ t('post-work.title') }}</label>
                </div>
                <button class="btn btn-flat btn-success" @click="print">
                    <i class="fa fa-print"></i> {{ t('datatable.print') }}
                </button>
                <button class="btn btn-flat btn-warning" @click="newEx">
                    <i class="fa fa-plus"></i> {{ t('datatable.add') }}
                </button>
                <data-table v-if="treino" class="table table-striped table-bordered" :data="exercicios" :columns="columns"
                    style="min-width: 100%;"
                    :options="{responsive: false, autoWidth: false, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>
                            </th>
                            <th>{{ t('general.actions') }}</th>
                            <th>{{ t('general.title') }}</th>
                            <th>{{ t('treinos.description') }}</th>
                            <th>{{ t('exercices.at') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

        <modal-component :classList="['col-11', 'col-sm-9', 'col-lg-7', 'p-1']" v-if="view"
            @hide="() => {this.view = null}"
            :show="true"
        >
            <img :src="view" class="col-12">
        </modal-component>
        <!-- ./Modals dos exercicios -->
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import { mapMutations, mapState, mapActions } from 'vuex';
    import { t } from '@/lang'
    import { decodeEntities, fillData, getExImage } from '@/utilities/HelpFunctions.js'
    import { check, exercicesToPrint } from '@/utilities/print.js'
    import api from '@/utilities/Api.js'
    import { ClassicEditor, Bold, Essentials, FontSize, FontSizeEditing, Italic, Mention, Paragraph, Undo, FontColorEditing, Alignment, Subscript, Superscript, FontColor, FontBackgroundColor} from 'ckeditor5';
    import 'ckeditor5/ckeditor5.css';

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "FormacoesView",
        components: {
            DataTable,
            ModalComponent,
            FormGroupComponent,
        },
        data() {
            return {
                postwork: false,
                treinoTo: 1,
                api,
                exercicios: [],
                copyTo: null,
                treino: null,
                printNow: false,
                onSelect: false,
                url: 'fitnesex',
                editor: ClassicEditor,
                editorConfig: {
                    plugins: [
                        Essentials,
                        Bold,
                        Paragraph,
                        Italic,
                        Mention,
                        Undo,
                        FontColorEditing,
                        Alignment,
                        Subscript,
                        Superscript,
                        FontColor,
                        FontBackgroundColor,
                        FontSize,
                        FontSizeEditing
                    ],

                    // lineHeight: {
                    //     options: [ 0, 0.5, 1, 1.5, 2, 2.5 ]
                    // },
                    fontSize: {
                        options: [16, 12, 14, 18, 20, 22, 24]
                    },
                    toolbar: ['undo', 'redo', '|', 'fontSize', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'alignment', '|', 'subscript', 'superscript'],
                },
                view: null,
                newImg: null,
            }
        },
        created() {
            document.title = "Exercicios fitnes"
            this.getExercices()
        },
        
        computed: {
            ...mapState(['loading']),
            columns() {
                return [
                    {data: 'id'},
                    {data: null, render: data =>  `
                        <div class='icheck-primary'>
                            <input type="checkbox" name='check${data.id}' id="check${data.id}" data-id='${data.id}' data-action='check'>
                            <label for='check${data.id}'></label>
                        </div>`
                    },
                    {data: null, render: data => `
                        <div class="row p-0 m-0 flex-nowrap">
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-primary' data-id='${data.id}' data-action='view'>
                                    <i class='fa fa-eye' data-id='${data.id}' data-action='view'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-warning' data-id='${data.id}' data-action='edit'>
                                    <i class='fa fa-edit' data-id='${data.id}' data-action='edit'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-danger' data-id='${data.id}' data-action='delete'>
                                    <i class='fa fa-trash' data-id='${data.id}' data-action='delete'></i>
                                </button>    
                            </div>
                            <div class='p-1 m-0 col-auto d-inline'>
                                <button class='btn btn-flat btn-success' data-id='${data.id}' data-action='download'>
                                    <i class='fa fa-download' data-id='${data.id}' data-action='download'></i>
                                </button>    
                            </div>
                        </div>`
                    },
                    {data: 'title'},
                    {data: null, render: data => `${(data.description || '-').substr(0, 40)}`},
                    {data: null, render: data => `${new Date(data.created_at).toLocaleString()}`}
                ]
            },
        },
        methods: {
            ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'put', 'delete', 'post']),
            decodeEntities,
            async getExercices() {
                this.SET_LOADING(true)

                const response = await this.get({
                    url: this.url + '/' + this.$route.params.id
                })
                
                if (response.status != 200) {
                    alert(response.data.message)
                    this.$router.push("/fitnes")
                } else {
                    this.treino = response.data.fitnes
                    console.log(response.data.fitnes)
                    this.treino.img_url = `${api.url}images/fitnes/${this.treino.img}`

                    this.exercicios = this.treino.exercices
                    document.title = this.treino.title
                    delete this.treino.exercicios
                    // decodeEntities
                    const excludes = ['exercices', 'user', 'rate']
                    for(const key in this.treino) {
                        if (!excludes.includes(key)) {
                            if (this.treino[key]) {
                                this.treino[key] = decodeEntities(this.treino[key])
                            }
                        }
                    }
                }
                this.SET_LOADING(false)
                if (!this.loading && this.$el.querySelector('#my-table')) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }

                check(null, [])
            },
            async download(exercice) {
                this.SET_LOADING(true)
                const url = await getExImage('', this.get, `fitnesgif?path=${exercice.path}`, 'image/gif')

                const a = document.createElement("a")
                a.setAttribute('download', `Gif ${exercice.title}.gif`)
                a.setAttribute('href', url)
                a.setAttribute('target', '_blank')
                
                a.click()
                alert(t('general.download-start'))

                this.SET_LOADING(false)
            },
            async handleClick(event) {
                if (event.target.hasAttribute("data-action")) {
                    
                    let [exercice] = this.exercicios.filter(ex => ex.id == event.target.getAttribute("data-id"))
                    switch(event.target.getAttribute("data-action")) {
                        case 'view':
                            this.SET_LOADING(true)
                            this.view = `${api.url}images/GIFS/${exercice.path}`
                            this.SET_LOADING(false)
                        break
                        case 'edit':
                            this.$router.push(`/fitnes_ex/${exercice.id}/view`)                            
                        break
                        case 'delete':
                            // confirm delete
                            if (confirm("Deseja realmente deletar esta Exercicio?")) {
                                this.SET_LOADING(true)
                                await this.delete({url: `fitnesex/${exercice.id}`})
                                await this.getExercices()
                                this.SET_LOADING(false)
                            }
                        break
                        case 'download':
                            this.download(exercice)
                        break
                        case 'check':
                            // exercice.check = event.target.checked
                            check(exercice)
                        break
                    }
                }
            },
            changeFile(event) {
                const [img] = event.target.files
                if (img) {
                    this.treino.img = img
                    this.treino.img_url = URL.createObjectURL(img)
                    this.newImg = img
                }
            },
            t,
            async update() {
                this.SET_LOADING(true)

                const formData = new FormData()
                
                const copy = Object.assign({}, this.treino)
                copy.img = "0"
                fillData(formData, copy)

                if (this.newImg) {
                    console.log(this.newImg)
                    formData.append("img", this.newImg);
                }
                const res = await this.post({
                    url: 'fitnes/' + this.treino.id,
                    data: formData,
                    formData: true
                })

                if (res.status < 200 || res.status > 299) {
                    alert("Ocorreu um erro")
                    console.log(res.data)
                } else {
                    alert("Actualizado com sucesso")
                    await this.getExercices()
                }

                this.SET_LOADING(false)
            },
            getPrintEx() {
                return exercicesToPrint()
            },
            print() {
                if (this.getPrintEx().length < 1) {
                    return null
                }
                const p = new URLSearchParams()
                p.append("fitnes", this.$route.params.id)
                let i = 0
                for(let ex of this.getPrintEx()) {
                   p.append(`exs[${i}]`, ex.id) 
                   i++
                }
                const lang = localStorage.getItem("lang_code") ? localStorage.getItem("lang_code").substring(0, 2).substring(0, 2):"pt"
                p.append("auth_token", sessionStorage.getItem("auth_token"))
                p.append("lang", lang)
                if (this.postwork) {
                    p.append('postwork', 1)
                }
                console.log(p.toString())
                open(api.printUrl + "/fitnes?" + p.toString(), "_blank")
            },
            edit(id) {
                this.$el.querySelector(`#${id}`).focus()
            },
            newEx() {
                this.$router.push('/fitnes_ex/' + this.$route.params.id)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
    .upload-btn {
        bottom: 0px;
        right: 0px;
    }
</style>
