<template>
    <div class="col-12 row p-4 px-2 m-0 justify-content-around">
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-header">
                <h3 class="h3">{{ t('week.create') }}</h3>
            </div>
            <form v-if="week.id" @submit.prevent="sendData">
                <div class="card-body table-responsive" id="my-table">
                    <div class="contentor col-12">
                        <div class="m-0 col-12" id="print">
                            <div class="row m-0 p-0 col-12">
                                <div class="col-12 col-md-3 p-0 p-1">
                                        <div class="col-12">
                                            <label for="dataInicio">{{ t('week.data1') }}</label>
                                            <input required type="date" name="dataInicio" id="dataInicio" v-model="week.dataInicio" class="form-control">
                                        </div>
                                        <div class="col-12"  style="min-height: 6rem;">
                                            <img :src="imgUrl" v-if="imgUrl" class="col-12">
                                            <input type="file" ref="file" name="file" @change="changeFile" class="d-none">
                                            <button type="button" class="btn btn-warning position-absolute bottom-0 right-0" @click="selectFile">
                                                <i class="fa fa-upload text-white fw-bold"></i>
                                            </button>
                                        </div>
                                </div>
                                <div class="col-12 col-md-9 p-0">
                                    <div class="row m-0 title col-12 p-1 align-items-center justify-content-between">
                                        <label for="header" class="col-12">{{ t('week.header') }}</label>
                                        <input required maxlength="120" type="text" placeholder="Nome do clube" id="header" class="form-control col-10 col-md-11" name="header" v-model="week.header"  :style="{
                                            color: config.header[0],
                                        }"/>
                                        <input type='color' v-model="config.header[0]" class="col-auto select-color p-0 text-bold" />
                                    </div>
                                    <div class="row m-0 subtitle col-12 p-1 align-items-center justify-content-between">
                                        <label for="subtitle" class="col-12">{{ t('week.subtitle') }}</label>
                                        <input required maxlength="120" type="text" class="form-control col-10 col-md-11" placeholder="Departamento" name="subtitle" v-model="week.subtitle" :style="{
                                            color: config.subtitle[0],
                                        }"/>
                                        <input type='color' v-model="config.subtitle[0]" class="col-auto select-color p-0 text-bold" />
                                    </div>
                                    <div class="row m-0 subtitle col-12 p-1 align-items-center justify-content-between">
                                        <label for="title" class=" col-12">{{ t('week.desc') }}</label>
                                        <input required maxlength="120" type="text" class="form-control col-10 col-md-11" :placeholder="t('week.title2')" name="title" v-model="week.title" :style="{
                                            color: config.info[0],
                                        }"/>
                                        <input type='color' v-model="config.info[0]" class="col-auto select-color p-0 text-bold" />
                                    </div>
                                    <div class="subtitle row m-0 subtitle col-12 p-1 align-items-center justify-content-between">
                                        <label for="subtitle" class=" col-12">{{ t('week.range') }}</label>
                                        <input maxlength="200" type="text" class="form-control col-10 col-md-11" :placeholder="`Semana ${dayjs.utc(week.dataInicio).format('DD.MM.YYYY')} - ${dayjs.utc(week.dataInicio).add(6, 'day').format('DD.MM.YYYY')}`" name="subtitle" v-model="config.range[1]" :style="{
                                            color: config.range[0],
                                        }"/>
                                        
                                        <input maxlength="120" type='color' v-model="config.range[0]" class="col-auto select-color p-0 text-bold" />
                                    </div>
                                </div>
                            </div>
                            <hr>
    
                            <div class="col-12 row p-1 m-0">
                                <div v-for="i in dates2" :key="i" class="px-3 p-0 m-0 col-auto">
                                    <input v-model="checks[dayIds[i]]" type="checkbox" :name="`check${i}`" :id="`check${i}`" />
                                    <label :for="`check${i}`">&nbsp;{{ t(`week.days.${i}`) }}</label>
                                </div>
                            </div>
    
                            <div v-if="dates.length > 0"  style="overflow-x: scroll">
                                <div class="col-12 row m-0 p-1 flex-nowrap">
                                    <div v-for="date in dates" :key="date.day()" class="col-10 col-md-5 col-lg-3 p-1" :id="this.dayIds[date.day()]">
                                        <div class="col-12 text-center">
                                            <div class="h5 text-center p-1 m-0" :style="{
                                                background: config[dayIds[date.day()]][0],
                                                color: config[dayIds[date.day()]][1],
                                            }">
                                                <input type='color' v-model="config[dayIds[date.day()]][0]" class="col-auto select-color p-0" />
                                                {{ t(`week.days.${date.day()}`) }}
                                                <input type='color' v-model="config[dayIds[date.day()]][1]" class="col-auto select-color p-0" />
                                            </div>
                                            <div class="small text-mutted mutted p-1 m-0 text-center" :style="{
                                                background: config[dayIds[date.day()] + 'data'][0],
                                                color: config[dayIds[date.day()] + 'data'][1],
                                            }">
                                                <input type='color' v-model="config[dayIds[date.day()] + 'data'][0]" class="col-auto select-color p-0" />
                                                {{ dayjs.utc(date).format("DD/MM/YYYY") }}
                                                <input type='color' v-model="config[dayIds[date.day()] + 'data'][1]" class="col-auto select-color p-0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <!-- MANHA -->
                                <div class="col-12 position-absolute text-center" :style="{
                                    background: config['manha'][0],
                                    color: config['manha'][1],
                                    zIndex: 4,
                                }">
                                    <input type='color' v-model="config['manha'][0]" class="col-auto select-color p-0" />
                                    <input type='text' v-model="config['manha'][2]" class="col-auto p-0 text-center text-center border-none" :style="{background: 'transparent', color: config['manha'][1],}">
                                    <input type='color' v-model="config['manha'][1]" class="col-auto select-color p-0" />
                                </div>
                                <div class="col-12 p-3"></div>
                                <div class="col-12 row m-0 p-1 flex-nowrap">
                                    <div v-for="date in dates" :key="date.day()" class="col-10 col-md-5 col-lg-3 p-1" :id="this.dayIds[date.day()]">
                                        <div class="col-12">
                                            <div class="col-12 p-0 py-1 position-relative">
                                                <input type='color' v-model="config[dayIds[date.day()] + 'MT'][0]" class="col-auto select-color p-0" />
                                                <input type='color' v-model="config[dayIds[date.day()] + 'MT'][1]" class="col-auto select-color p-0" />
                                                <input maxlength="20" :style="{
                                                    background: config[dayIds[date.day()] + 'MT'][0],
                                                    color: config[dayIds[date.day()] + 'MT'][1],
                                                }" class="form-control" placeholder="HORÁRIO" v-model="week[`${this.dayIds[date.day()]}TM`]" />
                                            </div>
                                            <div class="col-12 p-0 py-1">
                                                <input type='color' v-model="config[dayIds[date.day()] + 'MP'][0]" class="col-auto select-color p-0" />
                                                <input type='color' v-model="config[dayIds[date.day()] + 'MP'][1]" class="col-auto select-color p-0" />
                                                
                                                <input maxlength="20" :style="{
                                                    background: config[dayIds[date.day()] + 'MP'][0],
                                                    color: config[dayIds[date.day()] + 'MP'][1],
                                                }" class="form-control" placeholder="LOCAL" v-model="week[`${this.dayIds[date.day()]}PM`]" />
                                            </div>
                                            <input type='color' v-model="config[dayIds[date.day()] + 'MD'][0]" class="col-auto select-color p-0" />
                                            <div class="ck-container" :style="{
                                                background: config[dayIds[date.day()] + 'MD'][0],
                                            }">
                                                <ckeditor
                                                    v-model="week[`${this.dayIds[date.day()]}M`]"
                                                    :editor="editor"
                                                    :config="editorConfig"
                                                /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <!-- NOITE -->
                                <div class="col-12 position-absolute text-center" :style="{
                                    background: config['noite'][0],
                                    color: config['noite'][1],
                                    zIndex: 4,
                                }">
                                    <input type='color' v-model="config['noite'][0]" class="col-auto select-color p-0" />
                                    <input type='text' v-model="config['noite'][2]" class="col-auto p-0 text-center border-none" :style="{background: 'transparent', color: config['noite'][1]}">
                                    <input type='color' v-model="config['noite'][1]" class="col-auto select-color p-0" />
                                </div>
                                <div class="col-12 p-3"></div>
    
                                <div class="col-12 row m-0 p-1 flex-nowrap">
                                    <div v-for="date in dates" :key="date.day()" class="col-10 col-md-5 col-lg-3 p-1" :id="this.dayIds[date.day()]">
                                        <div class="col-12">
                                            <div class="col-12 p-0 py-1 position-relative">
                                                <input type='color' v-model="config[dayIds[date.day()] + 'NT'][0]" class="col-auto select-color p-0" />
                                                <input type='color' v-model="config[dayIds[date.day()] + 'NT'][1]" class="col-auto select-color p-0" />
                                                <input maxlength="20" :style="{
                                                    background: config[dayIds[date.day()] + 'NT'][0],
                                                    color: config[dayIds[date.day()] + 'NT'][1],
                                                }" class="form-control" placeholder="HORÁRIO" v-model="week[`${this.dayIds[date.day()]}TN`]" />
                                            </div>
                                            <div class="col-12 p-0 py-1">
                                                <input type='color' v-model="config[dayIds[date.day()] + 'NP'][0]" class="col-auto select-color p-0" />
                                                <input type='color' v-model="config[dayIds[date.day()] + 'NP'][1]" class="col-auto select-color p-0" />
                                                
                                                <input maxlength="20" :style="{
                                                    background: config[dayIds[date.day()] + 'NP'][0],
                                                    color: config[dayIds[date.day()] + 'NP'][1],
                                                }" class="form-control" placeholder="LOCAL" v-model="week[`${this.dayIds[date.day()]}PN`]" />
                                            </div>
                                            <input type='color' v-model="config[dayIds[date.day()] + 'ND'][0]" class="col-auto select-color p-0" />
                                            <!-- <label :for="`${this.dayIds[date.day()]}_noite`" class="p-0 m-0">Noite</label> -->
                                            <div class="ck-container" :style="{
                                                background: config[dayIds[date.day()] + 'ND'][0],
                                            }">
                                                <ckeditor
                                                    v-model="week[`${this.dayIds[date.day()]}N`]"
                                                    :editor="editor"
                                                    :config="editorConfig"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <label>{{t('week.obs')}} &emsp;</label>
                                <ckeditor
                                    v-model="week.obs"
                                    :editor="editor"
                                    :config="editorConfig"
                                    class="form-control"
                                />
                            </div>
    
                            <div class="p-2">
                                <button type="submit" class="btn btn-outline-primary m-1">{{t('treinos.update')}}</button>
                                <button type="button" @click="() => print(week.id)" class="btn btn-outline-success m-1">{{t('datatable.print')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang/index.js'
    import { formatDate, formatDate2 } from '@/lib/ManyFunctions'
    import {ClassicEditor, FontSize, Bold, Essentials, Italic, Mention, Paragraph, Undo, FontColorEditing, Alignment, Subscript, Superscript, FontColor, FontBackgroundColor} from 'ckeditor5'
    import 'ckeditor5/ckeditor5.css';
    import { mapMutations, mapState, mapActions } from 'vuex';
    import Api from '@/utilities/Api';
    import dayjs from 'dayjs'
    import utc from 'dayjs/plugin/utc';

        dayjs.extend(utc);

    export default {
        name: 'WeekView',
        data() {
            return {
                dayjs,
                imgUrl: null,
                week: {
                    id:null,
                    header: null,
                    subtitle: null,
                    img: null,
                    dataInicio: null,
                    title: null,
                    obs: null,
                },
                config: {},
                dayIds: ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'],
                checks: {'domingo': false, 'segunda': true, 'terca': false, 'quarta': false, 'quinta': false, 'sexta': false, 'sabado': false},
                editor: ClassicEditor,
                editorConfig: {
                    plugins: [
                        Essentials,
                        Bold,
                        Paragraph,
                        Italic,
                        Mention,
                        Undo,
                        FontColorEditing,
                        Alignment,
                        Subscript,
                        Superscript,
                        FontColor,
                        FontSize,
                        FontBackgroundColor,
                    ],
                    fontSize: {
                        options: [16, 12, 14, 18, 20, 22, 24]
                    },
                    toolbar: ['fontSize', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'alignment'],
                }
            }
        },
        created() {
            document.title = t('week.edit')
            const id = this.$route.params.id

            this.getWeek(id)
        },
        components: {},
        methods: {
            t, ...mapMutations(['SET_LOADING']),
            ...mapActions(['get', 'post']),
            formatDate, formatDate2,
            selectFile() {
                this.$refs.file.click()
                console.log(90)
            },
            async getWeek(id) {
                this.SET_LOADING(true)
                console.log(id)
                const {status, data: {semana}} = await this.get({url: "semana/" + id})
                console.log(semana)
                if (status == 200) {
                    for (let day of this.dayIds) {
                        this.week[`${day}M`] = semana.dia[`${day}M`]
                        this.week[`${day}N`] = semana.dia[`${day}N`]

                        this.week[`${day}TM`] = semana.time[`${day}M`]
                        this.week[`${day}TN`] = semana.time[`${day}N`]

                        this.week[`${day}PM`] = semana.place[`${day}M`]
                        this.week[`${day}PN`] = semana.place[`${day}N`]
                        
                        this.config = JSON.parse(semana.config)
                    }
                    this.week.header = semana.cabecalho
                    this.week.subtitle = semana.subtitulo
                    this.imgUrl = Api.url + `images/agendamentos/` + semana.img
                    this.week.dataInicio = dayjs.utc(semana.dataInicio).format("YYYY-MM-DD")
                    this.week.title = semana.title
                    this.week.id = semana.id
                    this.week.obs = semana.observacao
                } else {
                    alert("Ocorreu um erro")
                    this.$router.push("/weeks")
                }

                this.SET_LOADING(false)
            },
            changeFile(evt) {
                const [file] = evt.target.files

                if (file) {
                    this.imgUrl = URL.createObjectURL(file)
                    this.week.img = file
                }
            },
            async sendData() {
                const fd = new FormData()

                if (this.week.img) {
                    fd.append("img", this.week.img)
                }
                fd.append("cabecalho", this.week.header)
                fd.append("title", this.week.title)
                fd.append("observacao", this.week.obs)
                fd.append("subtitulo", this.week.subtitle)
                fd.append("dataInicio", dayjs.utc(this.week.dataInicio).format("YYYY-MM-DD"))
                fd.append("config", JSON.stringify(this.config))

                const dia = {}
                const place = {}
                const time = {}
                for (let day of this.dayIds) {
                    dia[`${day}M`] = this.week[`${day}M`]
                    dia[`${day}N`] = this.week[`${day}N`]
                    
                    place[`${day}M`] = this.week[`${day}PM`]
                    place[`${day}N`] = this.week[`${day}PN`]
                    
                    time[`${day}M`] = this.week[`${day}TM`]
                    time[`${day}N`] = this.week[`${day}TN`]
                }

                fd.append("dia", JSON.stringify(dia))
                fd.append("place", JSON.stringify(place))
                fd.append("time", JSON.stringify(time))

                this.SET_LOADING(true)
                const res = await this.post({url: 'semana/' + this.week.id, formData: fd, data: fd})
                console.log(res)
                if (res.status >= 200 && res.status < 300) {
                    alert(t('general.updated'))
                    // this.$router.push('/weeks')
                } else {
                    alert(res.data.message)
                }
                this.SET_LOADING(false)
            },

            print(id) {
                const p = new URLSearchParams()
                const lang = localStorage.getItem("lang_code") ? localStorage.getItem("lang_code").substring(0, 2).substring(0, 2):"pt"
                p.append("auth_token", sessionStorage.getItem("auth_token"))
                p.append("lang", lang)
                p.append("id", id)
                open(Api.printUrl + "/week/" + id + "?" + p.toString(), "_blanck")
            },
        },
        computed: {
            ...mapState(['loading']),
            dates() {
                const arr = []
                if (this.week.dataInicio) {
                    for (let i = 0; i < 7; i++) {

                        const d = dayjs.utc(this.week.dataInicio)
                        const d2 = d.add(i, "day")
                        if (this.checks[this.dayIds[d2.day()]]) {
                            arr.push(d2)
                        }
                    }
                }
                return arr
            },
            dates2() {
                const arr = []
                if (this.week.dataInicio) {
                    for (let i = 0; i < 7; i++) {
                        const d = dayjs.utc(this.week.dataInicio)
                        const d2 = d.add(i, "day")
                        arr.push(d2.day())
                    }
                }
                return arr
            }
        }
    }
</script>

<style lang="scss">
    .ck.ck-content.ck-editor__editable {
        min-height: 10rem !important;
    }

    .border-none {
        border: 0px !important;
    }
    .select-color {
        width: 2rem;
        background: transparent;
        border-radius: 0px;
    }

    .ck-container {
        .ck.ck-content.ck-editor__editable {
            background-color: transparent !important;
            background: transparent !important;
        }
    }
</style>
