<template>
    <div class="col-12 p-4 row justify-content-center">
        <h2 class="title col-12">
            {{t('gifs.edit')}}
        </h2>

        <div class="col-12 col-md-8 card card-outline card-primary p-2" v-if="!loading && treino.id">
            <form @submit.prevent="submit" class=" row flex-wrap col-12">
                <div class="col-12 p-2">
                    <label>{{t('exercices.title')}}</label>
                    <input type="text" v-model="treino.title" class="form-control" :placeholder="t('gifs.title')" required>
                </div>
                <div class="col-12 col-md-7">
                    <div class="px-2 col-12 pt-0">
                    <!-- duration	charge	series	group	 -->
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_duration" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('gifs.duration')" v-model="treino.h_duration">
                            <label for="h_duration" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.duration" class="form-control" required :placeholder="t('gifs.duration')">
                    </div>
                    <div class="px-2 col-12 pt-0">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_charge" :placeholder="t('gifs.charge')" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_charge">
                            <label for="h_charge" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.charge" class="form-control" required :placeholder="t('gifs.charge')">
                    </div>
                    <div class="px-2 col-12 pt-0">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_series" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('treinos.exercices.series')" v-model="treino.h_series">
                            <label for="h_series" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.series" class="form-control" required :placeholder="t('treinos.exercices.series')">
                    </div>
                    <div class="px-2 col-12 pt-0">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input id="h_group" maxlength="20" class="edit col-10 border-none text-bold" :placeholder="t('gifs.group')" v-model="treino.h_group">
                            <label for="h_group" role="button" class="fa fa-edit"></label>
                        </div>
                        <input type="text" v-model="treino.group" class="form-control" required :placeholder="t('gifs.group')">
                    </div>
                </div>
                <div class="col-12 col-md-5 position-relative overflow-auto">
                    <img class="image-responsive responsive col-12" style="min-height: 3rem" :src="img">
                    <button class="btn btn-warning position-absolute text-white bottom-0 right-0" type="button"
                        @click="openModal"
                    >
                        <i class="fa fa-image"></i>
                    </button>
                </div>
                <div class="col-12">
                    <div class="col-12 p-2">
                        <div class="row p-0 m-0 py-1 justify-content-between align-items-center">
                            <input :placeholder="t('treinos.objectives')" id="h_description" maxlength="20" class="edit col-10 border-none text-bold" v-model="treino.h_description">
                            <label for="h_description" role="button" class="fa fa-edit"></label>
                        </div>
                        <textarea class="form-control" rows="5" name="objetivos" v-model="treino.description" maxlength="800"></textarea>
                    </div>
                    <div class="py-2">
                        <button class="btn btn-primary mx-2" type="submit">
                            {{ t('treinos.update') }}
                        </button>
                        <button class="btn btn-warning mx-2" @click="back" type="button">
                            <i class="fa fa-arrow-left mr-2"></i>
                            {{ t('general.back') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <modal-component @hide="() => this.showModal = false" :show="showModal" :classList="['col-12']" :title="t('gifs.select')">
            <select-gif-component @selectGif="path => {this.treino.path = path; this.showModal = false}"></select-gif-component>
        </modal-component>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import { t } from '@/lang'
    import Api from '@/utilities/Api'
    import ModalComponent from '@/components/AdminLTE/Components/ModalComponent.vue'
    import SelectGifComponent from '../../components/SelectGifComponent.vue'

    export default {
        name: 'NewTreinoView',
        data() {
            return {
                showModal: false,
                treino: {
                    title: '',

                    duration: '',
                    charge: '',
                    series: '',
                    group: '',	
                    description: '',

                    h_duration: t('gifs.duration'),
                    h_charge: t('gifs.charge'),
                    h_series: t('treinos.exercices.series'),
                    h_group: t('gifs.group'),
                    h_description: t('treinos.objectives'),
                    path: null,
                    fitnes_id: null,
                    id: null
                }
            }
        },
        components: {
            ModalComponent,
            SelectGifComponent
        },
        async created() {
            document.title = t('gifs.menu')
            await this.getExercice()
        },
        computed: {
            ...mapState(['loading']),
            img() {
                return `${Api.url}images/GIFS/${this.treino.path}`
            }
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_LOADING']), t,
            back() {
                this.$router.push("/fitnes/" + this.treino.fitnes_id)
            },
            async getExercice() {
                this.SET_LOADING(true)
                
                const id = this.$route.params.id
                const response = await this.get({url: 'fitnesex/' + id + '/get'})
                if (response.status == 200) {
                    this.treino = response.data.exercice
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    this.$router.push('/login')
                } else if (response.status == 404) {
                    alert("Nao encontrado")
                    this.$router.push("/fitnes")
                } else {
                    alert(response.data.message)
                    this.$router.push("/fitnes")
                }
                this.SET_LOADING(false)
            },
            openModal() {
                this.showModal = true
            },
            async submit() {
                if (!this.treino.path) {
                    alert(t('gifs.select'))
                    return null
                }
                this.SET_LOADING(true)
                const response = await this.post({url: 'fitnesex/' + this.treino.id, data: this.treino})
                console.log(response)
                if (response.status == 200) {
                    alert(t('general.updated'))
                    this.$router.push('/fitnes_ex/' + response.data.exercice.id + '/view')
                } else {
                    alert(response.data.message)
                }
                console.log(response)
                this.SET_LOADING(false)
            }
        },
    }
</script>

<style lang="scss" scoped>
   
</style>
