<template>
    <div class="col-12 p-4">
        <h2 class="title">
            {{t('treinos.my')}}
        </h2>
        <div class="col-12 card card-outline card-primary p-2">
            <div class="card-body table-responsive" id="my-table">
                <data-table v-if="!loading" class="table table-striped table-bordered" :data="treinos" :columns="columns"
                    :options="{responsive: true, autoWidth: true, language: {
                        search: t('datatable.search'), zeroRecords: t('datatable.zero'),
                        info: t('datatable.info'),
                        infoFiltered: t('datatable.filter'),
                        paginate: {first: t('datatable.first'), last: t('datatable.last'), next: t('datatable.next'), previous: t('datatable.previous')}
                    },
                    dom: `<'pb-2'B><'row'<'col-sm-6'l><'col-sm-6'f>><tr><'row'<'col-sm-6'i><'col-sm-6'p>>`,
                }">
                    <thead>
                        <tr class="table-primary">
                            <th>#</th>
                            <th>{{ t('gifs.title') }}</th>
                            <th>{{ t('treinos.coach') }}</th>
                            <th>{{ t('gifs.student') }}</th>
                            <th>{{ t('treinos.date') }}</th>
                            <th>{{ t('treinos.objectives') }}</th>
                            <th>{{ t('general.actions') }}</th>
                        </tr>
                    </thead>
                </data-table>
            </div>
        </div>

    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import { t } from '@/lang/index.js'
    import { mapActions, mapMutations, mapState } from 'vuex'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)

    export default {
        name: "TreinosView",
        components: {
            DataTable
        },
        data() {
            return {
                treinos: [],
                showModal: false,
                columns: [
                    {data: 'id'},
                    {data: 'title'},
                    {data: 'teacher'},
                    {data: 'student'},
                    {data: 'data'},
                    {data: null, render: data => `${(data.objectives || '-').substr(0, 50)}`},
                    {data: null, render: data => `
                        <button class='btn btn-flat btn-primary m-1 d-inline' data-url='/fitnes/${data.id}'>
                            <i class='fa fa-eye' data-url='/fitnes/${data.id}'></i>
                        </button>
                        <button class='btn btn-flat btn-danger m-1 d-inline' data-del='${data.id}'>
                            <i class='fa fa-trash' data-del='${data.id}'></i>
                        </button>
                    `}
                ]
            }
        },
        created() {
            this.SET_LOADING(false)
            document.title = "Treinos fitnes"
        },
        async mounted() {
            await this.getTreinos()
        },
        computed: {
            ...mapState(['loading'])
        },
        methods: {
            ...mapActions(['get', 'post', 'put', 'delete']),
            ...mapMutations(['SET_USER', 'SET_LOADING']),
            async handleClick(event) {
                if (event.target.hasAttribute("data-url")) {
                    this.$router.push(event.target.getAttribute("data-url"))
                } else if (event.target.hasAttribute('data-del')) {
                    const id = event.target.getAttribute('data-del')
                    const del = confirm("Deseja realmente eliminar?")

                    if (del) {
                        this.SET_LOADING(true)
                        const response = await this.delete({url: 'fitnes/' + id})
                        if (response.status >= 200 && response.status < 300) {
                            alert(t("general.deleted"))
                            this.getTreinos()
                        } else {
                            this.SET_LOADING(false)
                            alert(response.data.message)
                        }
                    }
                }
            },
            async getTreinos() {
                this.SET_LOADING(true)
                const response = await this.get({url: 'fitnes'})
                console.log(response)
                if (response.status == 200) {
                    this.treinos = response.data.fitnes
                } else if (response.status == 402) {
                    alert(response.data.message)
                    this.$router.push('/payment')
                } else if (response.status == 401) {
                    this.SET_USER(null)
                    location = '/login'
                } else {
                    alert(response.data.message)
                }
                this.SET_LOADING(false)

                if (!this.loading) {
                    this.$el.querySelector('#my-table').addEventListener('click', this.handleClick);
                }
            },
            t
        }
    }
</script>

<style lang="scss" scoped>
    @import 'datatables.net-bs5';
</style>
