import { fabric } from "fabric"

export const createLineControl = (x, y, object) => {
    console.log(object)
    const canvas = object.canvas
    // traca uma linha recta entre os dois pontos
    const lc = new fabric.Line([x, y, object.x, object.y])
    canvas.add(lc)
    canvas.requestRenderAll()
}
