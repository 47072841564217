<template>
    <div>
        <h1 class="text-center text-bold text-danger col-12 py-4 px-1 h1">
            Error 404
        </h1>
        <div class="p-4 text-cente text-warning h3">
            <h3>{{ t("general.404") }}</h3>
        </div>
    </div>
</template>

<script>
    import { t } from '@/lang'
    export default {
        name: "NotFoundView",
        methods: {t},
        
    }
</script>
